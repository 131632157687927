<template>
	<div id="artcleList">
		<!-- 标题 -->
		<div class="labelBox">
			<div class="title">轮播图管理</div>
			<div>
				<el-button type="primary" size="medium" @click="add">添加轮播图</el-button>
			</div>
		</div>

		<!-- 轮播图列表 -->
		<el-table :data="list" style="width: 100%">
			<el-table-column prop="id" label="ID" width="50"></el-table-column>
			<el-table-column label="图片" width="250">
				<template slot-scope="scope">
					<el-image style="width: 100%; height: 100px" :src="scope.row.img" fit="contain"></el-image>
				</template>
			</el-table-column>
			<el-table-column label="链接">
				<template slot-scope="scope">
					<block v-if="scope.row.type == 1">
						<el-tag>文章</el-tag> {{scope.row.articleName}}
					</block>
					<block v-if="scope.row.type == 2">
						<el-tag>H5</el-tag> {{scope.row.url}}
					</block>
				</template>
			</el-table-column>
			<el-table-column label="所属小程序" width="200">
				<template slot-scope="scope">
					<el-tag v-for="item in scope.row.minipro" size="small" :key="item" style="margin:0 5px 5px 0">
						{{ item }}</el-tag>
				</template>
			</el-table-column>
			<el-table-column label="是否展示" width="100">
				<template slot-scope="scope">
					<el-tag type="success" size="small" v-if="scope.row.isShow">展示</el-tag>
					<el-tag type="danger" size="small" v-else>隐藏</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="createTime" label="添加时间" width="200"></el-table-column>
			<el-table-column label="操作" width="200">
				<template slot-scope="scope">
					<el-button type="text" @click="edit(scope.row.id)">编辑</el-button>
					<el-divider direction="vertical"></el-divider>
					<el-popconfirm title="确定删除吗？" @confirm="del(scope.row.id)">
						<el-button type="text" slot="reference" style="color:#F56C6C">删除</el-button>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>

		<!-- 添加文章弹窗 -->
		<el-dialog :title="editTitle" :visible.sync="editModal" width="50%" top="1vh">
			<div class="modalBox">
				<el-form :model="editForm" :rules="rules" ref="editForm" label-width="150px" class="demo-ruleForm">
					<!-- <el-form-item label="图片" prop="img" required>
            <el-upload class="avatar-uploader" action="http://upload.qiniup.com" :show-file-list="false" :data="qn"
              :on-success="handleAvatarSuccess" :before-upload="beforeUpload">
              <img v-if="editForm.img" :src="editForm.img" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon" style="background: #fafafa;"></i>
            </el-upload>
          </el-form-item> -->
					<el-form-item label="图片" prop="img" required>
						<el-upload class="avatar-uploader" action="http://upload.qiniup.com" :show-file-list="false"
							:data="qn" :on-success="handleAvatarSuccess" :before-upload="beforeUpload">
							<img v-if="editForm.img" :src="editForm.img" class="avatar" />
							<i v-else class="el-icon-plus avatar-uploader-icon" style="background: #fafafa;"></i>
						</el-upload>
					</el-form-item>
					<el-form-item label="所属小程序">
						<el-checkbox-group v-model="editForm.minipro">
							<el-checkbox :label="item.id" v-for="(item, index) in miniList" :key="index">{{ item.name }}
							</el-checkbox>
						</el-checkbox-group>
					</el-form-item>
					<el-form-item label="是否展示" prop="isShow">
						<el-switch v-model="editForm.isShow" :active-value="1" :inactive-value="0"></el-switch>
					</el-form-item>
					<el-form-item label="链接">
						<el-radio-group v-model="editForm.type">
							<el-radio :label="0">无</el-radio>
							<el-radio :label="1">文章</el-radio>
							<el-radio :label="2">H5</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="关联文章" prop="content" v-if="editForm.type == 1">
						<el-select v-model="editForm.articleId" placeholder="请选择">
							<el-option v-for="(item, index) in articleList" :key="index" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="H5链接" prop="content" v-if="editForm.type == 2">
						<el-input v-model="editForm.url"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="editModal = false">取 消</el-button>
				<el-button type="primary" @click="submitForm">保 存</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	const EditFormData = {
		img: '',
		isShow: 1,
		minipro: [],
		articleId: ''
	};

	export default {
		name: 'artcleList',
		data() {
			return {
				qn: {
					token: '',
					key: ''
				},
				qnUrl: 'http://healthqnytp.ifufan.com/',
				id: '',
				miniList: [],
				list: [],
				editModal: false,
				editTitle: '',
				articleList: [],
				editForm: EditFormData,
				rules: {
					img: [{
						required: true,
						message: '请上传列表图片',
						trigger: 'change'
					}]
				}
			};
		},

		created() {
			this.getList();
			this.getMiniList();
			this.getQnToken();
			this.getArticleData();
		},

		methods: {
			// 获取上传七牛云Token
			getQnToken() {
				this.$http.post('/qiniu/token').then(res => {
					if (res.data.code == 1) {
						this.qn.token = res.data.data;
					}
				});
			},

			// 上传前验证文件
			beforeUpload(file) {
				this.qn.key = `${new Date().getTime()}_${file.name}`;
				// debugger
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
				const isLt10M = file.size / 1024 / 1024 < 10;

				if (!isJPG) {
					this.$message.error('上传图片只能是 JPG/PNG 格式!');
				}
				if (!isLt10M) {
					this.$message.error('上传图片大小不能超过 10MB!');
				}
				return isJPG && isLt10M;
			},

			// 上传成功回调
			handleAvatarSuccess(res) {
				this.editForm.img = this.qnUrl + res.key;
				console.log(this.editForm.img)
			},

			add() {
				this.editForm = '';
				this.editForm = EditFormData;
				this.id = '';
				this.editTitle = '添加轮播图';
				this.editModal = true;
			},

			edit(id) {
				this.id = id;
				this.editTitle = '编辑轮播图';
				this.$http
					.post('/swiper/view', {
						id: this.id
					})
					.then(res => {
						if (res.data.code == 1) {
							this.editForm = res.data.data;
							this.editModal = true;
							this.getArticleData();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			},

			submitForm() {
				this.$refs['editForm'].validate(valid => {
					if (valid) {
						var data = {
							articleId: this.editForm.articleId,
							img: this.editForm.img,
							minipro: JSON.stringify(this.editForm.minipro),
							isShow: this.editForm.isShow,
							type: this.editForm.type,
							url: this.editForm.url
						};
						if (this.id) {
							data.id = this.id;
						}
						this.$http.post('/swiper/edit', data).then(res => {
							if (res.data.code == 1) {
								this.editModal = false;
								this.getList();
								this.$message.success(res.data.msg);
							} else {
								this.$message.error(res.data.msg);
							}
						});
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			del(id) {
				this.$http
					.post('/swiper/del', {
						id: id
					})
					.then(res => {
						if (res.data.code == 1) {
							this.getList();
							this.$message.success(res.data.msg);
						} else {
							this.$message.error(res.data.msg);
						}
					});
			},

			getList() {
				this.$http.post('/swiper/list', {}).then(res => {
					if (res.data.code == 1) {
						this.list = res.data.data;
					}
				});
			},

			getMiniList() {
				this.$http.post('/miniConfig/list', {}).then(res => {
					if (res.data.code == 1) {
						this.miniList = res.data.data;
					}
				});
			},

			getArticleData() {
				this.$http.post('/swiper/articleData', {}).then(res => {
					if (res.data.code == 1) {
						this.articleList = res.data.data;
					}
				});
			}
		}
	};
</script>

<style scoped>
	#artcleList {
		padding: 30px;
		background: #fff;
	}

	.modalBox {
		padding-right: 100px;
		/* overflow:scroll; */
		overflow-y: auto;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: auto;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: auto;
		display: block;
	}

	.paginationBox {
		margin: 20px 0;
		text-align: right;
	}
</style>
